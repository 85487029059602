import { Payment, RecurlyBasePayment, RecurlyRemotePayment } from '@iris/store/payments/types'
import { IAddressInfo, IPersonInfo, IrisStore } from '@iris/store/types'
import { CardElement, ElementsInstance, Recurly, TokenPayload } from '@recurly/recurly-js'
import { computed, getCurrentInstance, onMounted, onUnmounted, reactive, Ref, toRef, unref, watch, WritableComputedRef } from 'vue'
import axios, { AxiosError } from 'axios'
import * as Sentry from '@sentry/browser'
import { IAccountInitialPayment, IAccountInitialPaymentResponse } from '@iris/nestjs-interfaces-recurly'
import externalScript from '@iris/externalScript'
import { useRecurlyResendSmsMutation, useRecurlyStartRemotePaymentProcessMutation, RecurlyRemotePaymentFragment, useRecurlyRemotePaymentUpdatesSubscription, useRecurlyUpdateSessionMutation, RecurlyRemotePaymentState } from '../../queries'
import { formatMobilePhone } from '../../store/helpers'

export interface RecurlyFieldChangeResult {
  empty: boolean
  focus: boolean
  valid: boolean
}

export interface RecurlyChangeResult extends RecurlyFieldChangeResult {
  firstSix: string
  lastFour: string
  brand: string
  number: RecurlyFieldChangeResult
  expiry: RecurlyFieldChangeResult
  cvv: RecurlyFieldChangeResult
}

interface RecurlyTransactionError {
  /**
   * Object type
   */
  object: 'transaction_error';
  /**
   * Transaction ID
   */
  transactionId: string;
  /**
   * Category
   */
  category: string;
  /**
   * Code
   */
  code: string;
  /**
   * Customer message
   */
  message: string;
  /**
   * Merchant message
   */
  merchantAdvice: string;
  /**
   * Returned when 3-D Secure authentication is required for a transaction. Pass this value to Recurly.js so it can continue the challenge flow.
   */
  threeDSecureActionTokenId: string;

}

type ExtraOptionsForInitialPayment = Pick<IAccountInitialPayment, 'type' | 'deferredDate' | 'numberOfInstalments' | 'monthsInitialPayment' | 'addons'>

/** the actual payload from tokenise it also includes some basic card details */
export type RecurlyTokenPayloadWithCard = TokenPayload & {
  card: {
    brand: string
    first_six: string
    last_four: string
    exp_month: number
    exp_year: number
    issuing_country: string
    funding_source: string
  }
}

/**
 * All related to recurly only for use local or remote
 * @param param0 
 * @returns 
 */
export function useRecurlyElements({
  cardElementRefName,
  authContainerRefName,
  userDetauls,
  publicKey
} : {
  cardElementRefName: string
  authContainerRefName: string
  userDetauls: Ref<{
    firstName?: string
    lastName?: string
    address1?: string
    address2?: string
    city?: string
    country?: string
    postcode?: string
  }>
  /** public key for recurly js */
  publicKey: Ref<string> | string
}) {

  const currentInstance = getCurrentInstance()!
  const instance = currentInstance.proxy

  /** data from recurly elements */
  const recurlyData = ref<null | RecurlyChangeResult>(null)
  /** has the user done any interaction with recurly elements yet? */
  const userHasStartedInput = ref(false)

  const cardAuthActive = ref(false)

    // const cardElement = ref<>(null)
  watch(() => recurlyData.value ? recurlyData.value.focus : false, (focus) => {
    if (!focus) {
      userHasStartedInput.value = true
    }
  })
  

  const cardDetailsOk = computed<boolean>(() => {
    return recurlyData.value ? recurlyData.value.valid : false
  })
  const userErrors = computed<string[]>(() => {
    if (userHasStartedInput.value && recurlyData.value) {
      return [
        !recurlyData.value.number.valid && `Card number is ${recurlyData.value.number.empty ? 'empty' : 'invalid'}`,
        !recurlyData.value.expiry.valid && `Expiry Date is ${recurlyData.value.expiry.empty ? 'empty' : 'invalid'}`,
        !recurlyData.value.cvv.valid && `CVV is ${recurlyData.value.cvv.empty ? 'empty' : 'invalid'}`
      ].filter((m): m is string => !!m)
    }
    return []
  })

  const tokeniseCard = <T>(callback: (payload: RecurlyTokenPayloadWithCard) => Promise<T> ) => {
    return new Promise<RecurlyTokenPayloadWithCard>((resolve, reject) => {
      // overloads as want same return type as input type
      function truncateTo50 (str: string): string
      function truncateTo50 (str: undefined): undefined
      function truncateTo50 (str: string | undefined) {
        return str ? str.substring(0, 50) : str
      }
      recurly.token(elementsInstance!, {
        first_name: truncateTo50(userDetauls.value.firstName),
        last_name: truncateTo50(userDetauls.value.lastName),
        address1: truncateTo50(userDetauls.value.address1),
        address2: truncateTo50(userDetauls.value.address2),
        city: truncateTo50(userDetauls.value.city),
        country: truncateTo50(userDetauls.value.country),
        postal_code: truncateTo50(userDetauls.value.postcode)
      }, (err, arg) => {
        return err ? reject(err) : resolve(arg as RecurlyTokenPayloadWithCard)
      })
    }).then((payload) => {
      return callback(payload)
    }).then((result) => {
      element!.remove()
      element!.off()
      return result
    })
  }

  const do3DSecure = (actionTokenId: string) => {
    cardAuthActive.value = true
    return instance.$nextTick().then(() => {
      return new Promise<{
          id: string
          type: string
        }>((resolve, reject) => {
          const risk = recurly.Risk()
          const threeDSecure = risk.ThreeDSecure({
            actionTokenId
          })
          threeDSecure.attach(currentInstance.proxy.$refs[authContainerRefName] as HTMLElement)
          threeDSecure.on('error', reject)
          threeDSecure.on('token', resolve)
        })
    }).finally(() => {
      cardAuthActive.value = false
    })
  }


  const createElements = () => {
    const elements = recurly.Elements()
    elementsInstance = elements
    // let elements = instance.$stripe!.elements({ locale: 'en' })

    element = elements.CardElement({
      style: {
        lineHeight: '24px',
        placeholder: {
          color: '#495057'
        }
      }
      // style: {
      //   fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      //   lineHeight: '150%'

      // }
    })
    // debugger
    element.attach(currentInstance.proxy.$refs[cardElementRefName] as HTMLElement)
    element.on('change', (state: RecurlyChangeResult) => {
      // TODO reset local state if token as already been done.
      // console.log('recurly change event fired!')
      recurlyData.value = state
    })
    // element.on('submit', ({ state } : { state: RecurlyChangeResult }) => {
    //   console.log('SUBMIT', state)
    // })
  }

  let element: CardElement | undefined
  let elementsInstance: ElementsInstance | undefined


  const initElements = () => {
    return externalScript<Recurly>('https://js.recurly.com/v4/recurly.js', 'recurly', false).then(() => {
      // console.log('loaded recurly')
      // console.log(result)
      recurly.configure({
        publicKey: unref(publicKey)
      })
      createElements()
    //   const elements = recurly.Elements()
    //   const card = elements.CardElement({
    //     style: {
    //       // inputType: 'mobileSelect',
    //       fontColor: '#010101'
    //     }
    //   })
    //   card.attach(instance.refs['card-element'] as HTMLElement)
    })
  }

  onUnmounted(() => {
    if (element) {
      element.remove()
      element.off()
    }
    // element.emit('destroy')
    // TODO detach recurly event handlers
  })


  return {
    cardDetailsOk,
    userErrors,
    tokeniseCard,
    do3DSecure,
    cardAuthActive,
    initElements,
    recurlyData: readonly(recurlyData)
  }
}

/** error for 3ds errors so we know to require 3ds */
class ThreeDSecureRequiredError extends Error {
  constructor(message: string, readonly threeDSecureActionTokenId: string) {
    super(message)
  }
}

/** wrapper for recurly api backend actions */
function useRecurlyApiActions<R extends RecurlyBasePayment> ({
  onSuccessfulPayment, paymentTypeProps, value
} : {
  /** callback for successful payment to do further processing if required */
  onSuccessfulPayment?: (result: IAccountInitialPaymentResponse) => PromiseLike<void> | void
  /** extra properties for payment api */
  paymentTypeProps: Ref<ExtraOptionsForInitialPayment> | ExtraOptionsForInitialPayment
  /** payment value */
  value: WritableComputedRef<R>
}) {
  const currentInstance = getCurrentInstance()!
  const instance = currentInstance.proxy
  const store = instance.$store as IrisStore

  /** axios response is a 3d secure request */
  const isThreeDSecureError = (e: any): e is AxiosError<RecurlyTransactionError> & Required<Pick<AxiosError<RecurlyTransactionError>, 'response'>> => {
    return axios.isAxiosError(e) && e.response && e.response.data && e.response.status === 422 && e.response.data.code === 'three_d_secure_action_required' && e.response.data.threeDSecureActionTokenId
  }
  
  /** process the captured card token will throw a @see ThreeDSecureRequiredError if 3ds required */
  const doApiPaymentAction = (token: string, threeDSecureToken?: string) => {
    return store.dispatch('setupRecurlyAccount').then((accountId: string) => {
      return store.nestApi.processInitialPayment({
        ...unref(paymentTypeProps),
        accountId,
        amountInCents: value.value.amountInCents,
        subscriberId: store.state.createAccountResult!.subscriberId,
        subscriptionType: store.getters.newSubscriptionType,
        token,
        threeDSecureToken
      }).catch((error) => {
        if (isThreeDSecureError(error)) {
          throw new ThreeDSecureRequiredError(error.response.data.message, error.response.data.threeDSecureActionTokenId)
        }
        throw error
      }).then((result) => {
        // mark and save receipt number
        value.value = {
          ...value.value,
          receiptNumber: result.receiptNumber,
          billingMethodId: result.billingMethodId,
          cardExpiryMonth: result.cardExpiryMonth,
          cardExpiryYear: result.cardExpiryYear,
          cardFirstSix: result.cardFirstSix,
          cardLastFour: result.cardLastFour,
          cardType: result.cardType,
          finalised: true
        }
        return result
    }).catch((error) => {
      // other error unpack response for message
      if (axios.isAxiosError(error) && error.response && error.response.data) {
        throw new Error(error.response.data.message)
      } else {
        // instance.error = error
        Sentry.captureException(error)
        throw error
      }
    }).then((result) => {
      // console.log(result)
      return Promise.resolve(onSuccessfulPayment ? onSuccessfulPayment(result) : undefined).then(() => result)
    })
  })
  }
  return {
    doApiPaymentAction
}
}

/** stub recurly remote */
export function useRecurlyRemote<R extends Extract<Payment, RecurlyRemotePayment>> ({ onSuccessfulPayment, paymentTypeProps, value }: {
  /** callback for successful payment to do further processing if required */
  onSuccessfulPayment?: (result: IAccountInitialPaymentResponse) => PromiseLike<void> | void
  /** extra properties for payment api */
  paymentTypeProps: Ref<ExtraOptionsForInitialPayment> | ExtraOptionsForInitialPayment
  /** payment value */
  value: WritableComputedRef<R>,
}) {
  // console.log(value)
  // console.log(value.value)
  const currentInstance = getCurrentInstance()!
  const instance = currentInstance.proxy
  const store = instance.$store as IrisStore

  const mounted = ref(false)

  const { doApiPaymentAction } = useRecurlyApiActions({
    paymentTypeProps,
    value,
    onSuccessfulPayment
  })

  onMounted(() => {
    // console.log('mounted')
    mounted.value = true
  })

  const startRemotePaymentMutation = useRecurlyStartRemotePaymentProcessMutation({})
  const resendLinkMutation = useRecurlyResendSmsMutation({})
  const updateSession = useRecurlyUpdateSessionMutation({})


  // fetch initial data
  // const query = useRecurlyGetRemotePaymentLazyQuery(computed(() => ({
  //   id: mounted.value ? value.value.remotePaymentSessionId : ''
  // })))
  // query.onResult((result) => {
  //   if (result.data) {
  //     state.value = result.data.recurlyGetRemotePayment
  //   }
  // })

  const subscription = useRecurlyRemotePaymentUpdatesSubscription(computed(() => ({
    id: mounted.value ? value.value.remotePaymentSessionId : '',
    frontend: false
  })), computed(() => ({
    enabled: mounted.value && !!value.value?.remotePaymentSessionId
  })))

  subscription.onResult((result) => {
    if (result.data) {
      state.value = result.data.recurlyRemotePaymentWatcher
    }
  })

  updateSession.onDone(result => {
    state.value = result.data.recurlyUpdateSession
  })
  // , computed(() => ({
  //   enabled: !!value.value.remotePaymentSessionId
  // })))

  const processing = ref(false)
  const cardAuthActive = ref(false)
  const error = ref<Error | null>(null)

  const state = ref<RecurlyRemotePaymentFragment | null>(null)

  const clientVisitingRemotePage = computed<boolean>(() => state.value?.pageVisits > 0)
  const waitingForClientToVisit = computed<boolean>(() => !state.value?.pageVisits)
  const clientEnteringCardDetails = computed<boolean>(() => clientVisitingRemotePage.value && state.value?.state === RecurlyRemotePaymentState.EnteringCardDetails || state.value?.state === RecurlyRemotePaymentState.CardDetailsGood)

  // watch(state, (newState) => {
  //   console.log(newState)
  // })

  watchEffect(() => {
    if (!mounted.value) {
      // console.log('not yet mounted!')
      return
    }
    if (updateSession.loading.value) {
      // console.log('mutating session')
      return
    }
    // COMBINE THESE 2
    if (mounted.value && !state.value && value.value?.remotePaymentSessionId) {
      // console.log(`onmounted state.value=${state.value} remotesession: ${value.value?.remotePaymentSessionId}`)
      return
    }
    if (!state.value) {
      // console.log('no state yet!')
      return
    }

    if (state.value.state === RecurlyRemotePaymentState.Failure || state.value.state === RecurlyRemotePaymentState.Success) {
      cardAuthActive.value = false
    }
    // deal with errors
    if (state.value.state === RecurlyRemotePaymentState.Failure) {
      error.value = new Error(state.value.message)
    }
    if (state.value.state !== RecurlyRemotePaymentState.Failure) {
      error.value = null
    }

    // END COMBINE these 2!
    // if we have card details to use submit for payment!
    if (state.value.state === RecurlyRemotePaymentState.SubmittingCardDetails && state.value.token) {
      processing.value = true
      return updateSession.mutate({
        id: value.value.remotePaymentSessionId,
        state: RecurlyRemotePaymentState.SubmittingPayment
      }).then(() => {
        // api call to backend!
        return doApiPaymentAction(state.value.token.token, state.value.threeDToken?.token).then(() => {
          return updateSession.mutate({
            id: value.value.remotePaymentSessionId,
            state: RecurlyRemotePaymentState.Success,
            message: `${value.value.type === 'deferred-recurly-remote' ? 'Card Save' : 'Payment'} Successful! Your transaction has been completed. Please close this page to finalise the process.`
          })
        }).catch(e => {
          if (e instanceof ThreeDSecureRequiredError) {
            // 3ds init
            cardAuthActive.value = true
            return updateSession.mutate({
              id: value.value.remotePaymentSessionId,
              state: RecurlyRemotePaymentState.ThreeDSecureRequired,
              message: e.message,
              threeDSessionID: e.threeDSecureActionTokenId
            })
          }
          return updateSession.mutate({
            id: value.value.remotePaymentSessionId,
            state: RecurlyRemotePaymentState.Failure,
            message: e.message
          })
          // throw e // prevent further 
        }).finally(() => {
          processing.value = false
        })
      })
    }
  })

  const canEdit = computed(() => {
    return !value.value.remotePaymentSessionId
  })

  const sendLink = () => {
    processing.value = true
    error.value = null
    return Promise.resolve().then(() => {
      if (value.value.remotePaymentSessionId) {
        return resendLinkMutation.mutate({
          id: value.value.remotePaymentSessionId,
          baseUrl: `${window.location.origin}/card`
        }).then(r => r.data.recurlyReSendSMSToCustomer)
      }
      const user = store.getters.getInfoForPayerType(value.value.paidBy)
      let paymentDate: string
      if (value.value.type === 'deferred-recurly-remote') {
        if(!value.value.dueOn) { // make sure dueOn is set
          return
        }
        paymentDate = value.value.dueOn
      }
      // do nothing needs to send link to customer
      return startRemotePaymentMutation.mutate({
        amount: value.value.amountInCents / 100,
        baseUrl: `${window.location.origin}/card`,
        phone: formatMobilePhone(store.state.familyInformation.mobilePhone),
        paymentDate,
        userInfo: {
          address1: user.address1,
          address2: user.address2,
          city: user.city,
          country: user.country,
          firstName: user.firstName,
          lastName: user.lastName,
          postcode: user.postcode
        }
      }).then(r => r.data.recurlyStartRemotePayment)
    }).then(resp => {
      state.value = resp
      value.value = {
        ...value.value,
        remotePaymentSessionId: resp.id
      }
    }).catch((e) => {
      error.value = e
    }).finally(() => {
      processing.value = false
    })
  }

  const signalDeletion = () => {
    if (value.value.remotePaymentSessionId) {
      return updateSession.mutate({
        id: value.value.remotePaymentSessionId,
        state: RecurlyRemotePaymentState.Success,
        message: `The payment has been closed by your consultant, and they'll either resend a new link via SMS or use an alternative method to complete the transaction.`
      })
    }
    return Promise.resolve()
  }


  return {
    cardDetailsOk: ref(false),
    // userErrors: ref([]),
    canEdit,
    processing,
    cardAuthActive,
    error,
    sendLink,
    state,
    clientVisitingRemotePage,
    signalDeletion,
    waitingForClientToVisit,
    clientEnteringCardDetails
  }
}

export function useRecurly<R extends RecurlyBasePayment> ({ onSuccessfulPayment, paymentTypeProps, value, publicKey }: {
  /** callback for successful payment to do further processing if required */
  onSuccessfulPayment?: (result: IAccountInitialPaymentResponse) => PromiseLike<void> | void
  /** extra properties for payment api */
  paymentTypeProps: Ref<ExtraOptionsForInitialPayment> | ExtraOptionsForInitialPayment
  /** payment value */
  value: WritableComputedRef<R>,
  /** public key for recurly js */
  publicKey: Ref<string> | string
}) {

  const { doApiPaymentAction } = useRecurlyApiActions({
    paymentTypeProps,
    value,
    onSuccessfulPayment
  })

  const payerDetails = computed< IPersonInfo & IAddressInfo>(() => {
    return store.getters.getInfoForPayerType(value.value.paidBy)
  })

  const { tokeniseCard, cardDetailsOk: recurlyCardDetalsOk, userErrors, do3DSecure, cardAuthActive, initElements } = useRecurlyElements({
    authContainerRefName: 'authContainer',
    cardElementRefName: 'cardElement',
    userDetauls: payerDetails,
    publicKey
  })
  const currentInstance = getCurrentInstance()!
  const instance = currentInstance.proxy
  const store = instance.$store as IrisStore

  const data = reactive({
    processing: false,
    error: null as null | Error | string,
  })

  const cardDetailsOk = computed<boolean>(() => {
    return recurlyCardDetalsOk.value && Object.keys(instance.$v).filter(k => k[0] !== '$' && k !== 'finalised').every(key => !instance.$v[key].$invalid)
  })


  const canEdit = computed<boolean>(() => {
    return !(value.value.finalised || data.processing)
  })



  /** process a valid payment once card details are good */
  const processPayment = () => {
    data.processing = true
    data.error = null
    
    return tokeniseCard((token): Promise<IAccountInitialPaymentResponse> => {
      return doApiPaymentAction(token.id).catch(error => {
        if (error instanceof ThreeDSecureRequiredError) {
          return do3DSecure(error.threeDSecureActionTokenId).then(result => {
            return doApiPaymentAction(token.id, result.id)
          })
        }
        throw error
      })
    })
    // }) // end of getting tokenised from recurly then
      .catch((error) => {
        // other error unpack response for message
        if (axios.isAxiosError(error) && error.response && error.response.data) {
          data.error = error.response.data
        } else {
          data.error = error
        }
        // instance.error = error
        Sentry.captureException(error)
      }).then(() => {
        data.processing = false
      })
  } // end processPayment

  onMounted(() => {
    if (!value.value.finalised) {
      initElements()
    }
  })


  return {
    processPayment,
    cardDetailsOk,
    userErrors,
    canEdit,
    processing: toRef(data, 'processing'),
    cardAuthActive,
    error: toRef(data, 'error')
  }
}
